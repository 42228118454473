var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pointVolunteerList"},[_c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("./img/pointVolunteer.png"),"alt":""}}),_c('div',{staticClass:"detailInfo"},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":_vm.detail.avatar
              ? _vm.detail.avatar
              : require('@/assets/img/default_avatar.png'),"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.detail.nickName || "--"))]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"infoList"},[_c('div',{staticClass:"title"},[_vm._v("志愿积分排行榜")]),_c('div',{staticClass:"text"},[_vm._v(" 第"),_c('span',[_vm._v(_vm._s(_vm.detail.pointRanking))]),_vm._v("名 ")])]),_c('div',{staticClass:"infoTotal"},[_c('div',{staticClass:"title"},[_vm._v("志愿总积分")]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.detail.volunteerCount))])])]),_vm._m(0),_c('div',{staticClass:"point"},[_vm._v(_vm._s(_vm.detail.volunteerAvailablePoint))])])]),_c('div',{staticClass:"main"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"main-bottom"},[_c('div',{staticClass:"list"},[_c('v-list',{ref:"list",attrs:{"finished":_vm.finished,"finishedText":_vm.finishedText},on:{"onLoad":_vm.onLoad}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"oItem"},[_c('div',{staticClass:"oItemRank"},[(index === 0)?_c('img',{attrs:{"src":require("./img/gold.png"),"alt":""}}):(index === 1)?_c('img',{attrs:{"src":require("./img/silver.png"),"alt":""}}):(index === 2)?_c('img',{attrs:{"src":require("./img/copper.png"),"alt":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(index + 1))])]),_c('div',{staticClass:"oItemName"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.avatar
                        ? item.avatar
                        : require('@/assets/img/default_avatar.png'),"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.nickName))])]),_c('div',{staticClass:"oItemTime"},[_vm._v(" "+_vm._s(_vm.serviceTimeFormat(item.serviceTime))+" ")]),_c('div',{staticClass:"oItemPoint"},[_c('div',{staticClass:"pointNum"},[_vm._v(_vm._s(item.totalVolunteerPoint))])])])])}),0)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip"},[_c('div',{staticClass:"text"},[_vm._v("志愿可用积分")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title"},[_c('div',{staticClass:"aside"},[_vm._v("前."),_c('span',[_vm._v("100")])]),_c('div',{staticClass:"title"},[_c('span',[_vm._v("志愿积分排行榜")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-top"},[_c('div',{staticClass:"field rank"},[_vm._v("排名")]),_c('div',{staticClass:"field name"},[_vm._v("名称")]),_c('div',{staticClass:"field point"},[_vm._v("服务时长")]),_c('div',{staticClass:"field point"},[_vm._v("志愿积分")])])
}]

export { render, staticRenderFns }